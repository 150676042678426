.homePage {
	padding: 50px 10px 0px 10px;
	h1 {
		display: none;
	}
	.homePage-wrapper {
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-wrap: wrap;
		box-sizing: border-box;
		row-gap: 5vh;
		width: 100%;
		margin-bottom: 20px;
		@media screen and (min-width: 769px) {
			width: 85%;
		}
		div {
			color: #000;
			display: flex;
			flex-direction: column;
			// justify-content: center;
			align-items: center;
			aspect-ratio: 4/4;
			width: 40%;
			font-size: 20px;
			cursor: pointer;
			@media screen and (min-width: 769px) {
				width: 30%;
			}
			@media screen and (min-width: 1024px) {
				width: 22%;
			}
			a {
				text-decoration: none;
				width: 100%;
				height: 100%;
				font-size: 20px;
				cursor: pointer;
				position: relative;
				text-decoration: none;
				h2,
				h3 {
					color: #000;
					height: 10%;
					margin: 0;
					font-size: 50%;
					@media screen and (min-width: 426px) and (max-width: 1024px) {
						font-size: 70%;
					}
					@media screen and (min-width: 1025px) {
						font-size: 80%;
					}
				}
				.homePage-wrapper-link-photo {
					background:no-repeat center center;
					background-size: contain;
					width: 100%;
					height: 85%;
					object-fit: cover;
				}
				&:hover,
				&:focus {
					color: #f850b3;
					transform: scale(1.1);
					font-weight: bold;
					transition: transform 0.5s;
				}
			}
		}
	}
}
