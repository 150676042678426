.errorPage{
  justify-content: center;
  background-color: #f850b2d2;
  gap: 0!important;
  .title{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 50vh;
    @media screen and (min-width: 769px) {
      gap: 40px;
    }
    h1,h2{
      font-size: 110px;
      margin: 0;
      @media screen and (min-width: 769px) {
        font-size: 300px;
      }
    }
    img{
      height: 30%;
      @media screen and (min-width: 769px) {
        height: 50%;
      }
    }
  }
  h3{
    font-size: 20px;
    @media screen and (min-width: 425px) and (max-width: 768px) {
      font-size: 25px;
    }
    @media screen and (min-width: 769px) {
      font-size: 40px;
    }
  }
}