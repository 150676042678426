.configurator-container {
	.config-wrapper {
		width: 100%;
		@media screen and (min-width: 769px) {
			width: 85%;
		}
		h1 {
			font-size: 18px;
			margin-top: 50px;
			@media screen and (min-width: 426px) and (max-width: 768px) {
				font-size: 22px;
			}
			@media screen and (min-width: 769px) and (max-width: 1024px) {
				font-size: 26px;
			}
			@media screen and (min-width: 1025px) {
				font-size: 32px;
				margin-top: 0;
			}
		}
	}
	.configurator-wrapper {
		min-height: 55vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		@media screen and (min-width: 769px) {
			flex-direction: row;
		}
		.display-selection {
			width: 100%;
			@media screen and (min-width: 769px) {
				width: 40%;
			}
			&-img {
				width: 40%;
				max-width: 200px;
				@media screen and (min-width: 769px) {
					width: 80%;
				}
			}
			h3 {
				margin: 0;
			}
		}

		.product,
		.form,
		.candies,
		.deco {
			width: 100%;
			margin-top: 20px;
			@media screen and (min-width: 769px) {
				margin-top: 50px;
			}
			h2,
			h3 {
				font-size: 12px;
				@media screen and (min-width: 426px) and (max-width: 768px) {
					font-size: 18px;
				}
				@media screen and (min-width: 769px) and (max-width: 1024px) {
					font-size: 18px;
				}
				@media screen and (min-width: 1025px) {
					font-size: 1.5em;
				}
			}
		}
	}
	.configurator-recap {
		display: flex;
		width: 100%;
		height: 7vh;
		margin-top: 20px;
		padding: 10px 0;
		box-sizing: border-box;
		background-color: #f850b3;
		color: black;
		@media screen and (min-width: 769px) {
			height: 12vh;
		}
		div {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 20%;
			overflow-y: scroll;
			scrollbar-width: none;
			h2,
			h4 {
				text-align: center;
				margin: 0;
			}
			h2 {
				font-weight: bold;
				font-size: 8px;
				@media screen and (min-width: 426px) and (max-width: 768px) {
					font-size: 12px;
				}
				@media screen and (min-width: 769px) and (max-width: 1024px) {
					font-size: 18px;
				}
				@media screen and (min-width: 1025px) {
					font-size: 1.5em;
				}
			}
			h4 {
				font-size: 8px;
				font-weight: normal;
				@media screen and (min-width: 426px) and (max-width: 768px) {
					font-size: 10px;
				}
				@media screen and (min-width: 769px) {
					font-size: 1.2em;
				}
			}
		}
	}
	.configurator-btn {
		display: flex;
		justify-content: center;
		gap: 20px;
		margin-bottom: 10px;
		:nth-child(1),
		:nth-child(2) {
			width: 30%;
			aspect-ratio: 16/3;
			border-radius: 20px;
			background-color: #f850b3;
			color: black;
			font-weight: bold;
			font-size: 12px;
			cursor: pointer;
			@media screen and (min-width: 769px) {
				font-size: 1.2em;
				width: 30%;
			}
		}
	}
}
.product {
	.product-btn {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: center;
		row-gap: 20px;
		width: 100%;
		margin: 30px 0;
		@media screen and (min-width: 769px) {
			margin: 50px 0;
		}
		.productBtn {
			width: 40%;
			max-width: 210px;
			aspect-ratio: 1/1;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			@media screen and (min-width: 769px) {
				max-width: 350px;
				width: 20%;
			}
			&.selected {
				box-shadow: #f850b3 0 0 20px 5px;
			}
		}
		.cone {
			background-image: url("../../../public/assets/cones-bonbon-2.webp");
		}
		.brochette {
			background-image: url("../../../public/assets/brochette-3.webp");
		}
		.sachet {
			background-image: url("../../../public/assets/sachets.webp");
		}
		.cup {
			background-image: url("../../../public/assets/cup-1.webp");
		}
	}
}
.form {
	button {
		// width: 20%;
		height: 20vh;
		aspect-ratio: 1/1;
		background-size: 100% 100%;
		background-position: center;
		background-repeat: no-repeat;
		border: none;
		background-color: transparent;
		margin-top: 30px;
		margin-right: 10px;
		cursor: pointer;
		@media screen and (min-width: 769px) {
			height: 40vh;
			margin-top: 0;
		}
		&.selected {
			box-shadow: #f850b3 0 0 20px 5px;
		}
	}
	.cône {
		background-image: url("../../../public/assets/cone-chamallow-malteser.webp");
	}
	.cone {
		background-image: url("../../../public/assets/sachet-pop-corn.webp");
	}
	.carré {
		background-image: url("../../../public/assets/sachet-carre.webp");
	}
	.brochette {
		background-image: url("../../../public/assets/brochette.webp");
	}
	.cup {
		background-image: url("../../../public/assets/cup.webp");
	}
}

.candies {
	display: flex;
	flex-direction: column;
	gap: 5vh;
	h2 {
		margin-bottom: 25px;
	}
	.categories,
	.options-list {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		box-sizing: border-box;
		padding: 0 3%;
		@media screen and (min-width: 769px) {
			gap: 20px;
			justify-content: center;
		}
		.category-btn,
		.option-btn {
			width: 25%;
			aspect-ratio: 3/1;
			border-radius: 10px;
			font-size: 14px;
			font-weight: bold;
			cursor: pointer;
			@media screen and (min-width: 769px) and (max-width: 1024px) {
				font-size: 16px;
			}
			@media screen and (min-width: 1025px) {
				width: 20%;
				font-size: 20px;
			}
			&.active {
				box-shadow: #f850b3 0 0 20px 5px;
			}
		}
		.option-btn {
			width: 45%;
			margin-bottom: 20px;
			@media screen and (min-width: 426px) and (max-width: 1024px) {
				width: 30%;
			}
			@media screen and (min-width: 1025px) {
				width: 22%;
				font-size: 18px;
			}
		}
	}
}
.deco {
	display: flex;
	flex-direction: column;
	align-items: center;
	input[type="checkbox"] {
		margin-top: 20px;
		cursor: pointer;
		width: 30px;
		height: 30px;
	}
	textArea {
		margin-top: 30px;
		width: 80%;
		height: 100px;
		font-size: 20px;
		resize: none;
		padding: 10px;
		@media screen and (min-width: 769px) and (max-width: 1024px) {
			height: 150px;
		}
		@media screen and (min-width: 1025px) {
			width: 50%;
		}
	}
}
