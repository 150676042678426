.facebook-reviews {
	height: 300px;
	width: 100%;
	display: flex;
	overflow-x: scroll;
	overflow-y: hidden;
	scrollbar-width: thin;
	gap: 10px;
  @media screen and (min-width: 769px) {
    width: 85%;
  }
  @media screen and (min-width: 1025px) {
    height: 250px;
  }

	iframe {
		width: 80%;
		height: auto;
		min-width: 300px;
    @media screen and (min-width: 1025px) {
      width: 30%;
    }
	}
}
