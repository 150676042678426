.commande {
	gap: 0 !important;
	h1 {
		margin-top: 50px;
		margin-bottom: 0;
	}
	.commande-wrapper {
		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		height: 90%;
		overflow: hidden;
		@media screen and (min-width: 769px) {
			flex-direction: row;
		}
		h2,
		h3 {
			font-size: 1.5em;
		}
		.recap-commande {
			display: flex;
			flex-direction: column;
			width: 100%;
			row-gap: 15px;
			margin-bottom: 70px;
			height: 97%;
			overflow-y: scroll;
			padding-bottom: 20px;
			@media screen and (min-width: 769px) {
				width: 59%;
			}
			.recap-commande-total {
				margin-top: 0;
				font-size: 18px;
			}
			.recap-commande-item {
				display: flex;
				padding-left: 5%;
				gap: 2%;
				.recap-commande-img {
					width: 40%;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: top;
				}
				.recap-commande-info {
					width: 50%;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					&-name {
						font-weight: bold;
						font-size: 16px;
						@media screen and (min-width: 769px) {
							font-size: 20px;
						}
					}
					p {
						margin-top: 0;
						text-align: left;
					}
				}
			}
		}
		.payement-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			height: 100%;
			row-gap: 15px;
			@media screen and (min-width: 769px) {
				width: 40%;
			}
			div {
				width: 100%;
				display: flex;
				justify-content: center;
				label {
					font-size: 18px;
					color: black;
					font-weight: normal;
					width: 90%;
					display: flex;
					justify-content: space-between;
					@media screen and (min-width: 1025px) {
						width: 70%;
					}
					input,
					textarea {
						color: black;
						font-weight: normal;
						font-size: 14px;
						width: 66%;
						background-color: transparent;
						border: none;
						resize: none;
						@media screen and (min-width: 1025px) {
							font-size: 18px;
						}
					}
					input {
						padding: 0;
						height: 2em;
					}
				}
			}
			.clickandcollect {
				label {
					justify-content: center;
					font-size: 25px;
					font-weight: bold;
					color: green;
					input[type="checkbox"] {
						width: 30%;
						height: 100%;
						accent-color: rgb(1, 255, 1);
					}
				}
			}
			.card-element {
				width: 100%;
				max-width: 380px;
			}
			h3 {
				margin-bottom: 4%;
			}
			button {
				width: 40%;
				aspect-ratio: 6 / 1;
				background-color: #f850b3;
				border: none;
				border-radius: 5px;
				color: #fff;
				font-size: 16px;
				cursor: pointer;
				margin-top: 7%;
			}
			.error {
				white-space: pre-line;
			}
		}
	}
}
