.category {
	h1 {
		display: none;
	}
	.category-wrapper {
		margin-bottom: 50px;
		margin-top: 70px;
		padding: 0 1%;
		box-sizing: border-box;
		height: 90%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		column-gap: 20px;
		row-gap: 40px;
		width: 100%;
		@media screen and (min-width: 769px) {
			width: 85%;
		}
		.scrollTop {
			position: fixed;
			bottom: 10vh;
			right: 15.3%;
			width: 50px;
			height: 50px;
			background-color: #f850b3;
			border-radius: 50%;
			border: none;
			color: white;
			font-size: 20px;
			cursor: pointer;
		}

		.category-card {
			width: 45%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			height: 50vh;
			box-shadow: 0 0 5px black;
			padding: 0 2px 2px 2px;
			box-sizing: border-box;
			@media screen and (max-width: 768px) {
				height: 40vh;
			}
			@media screen and (max-width: 425px) {
				height: 30vh;
			}
			&:hover,
			&:focus {
				box-shadow: inset 0 0 10px black;
			}
			a {
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-around;
				h2 {
					font-size: 100%;
					height: 10%;
					text-align: center;
					@media screen and (max-width: 768px) {
						font-size: 80%;
					}
					@media screen and (max-width: 425px) {
						font-size: 70%;
					}
				}
				width: 100%;
				text-decoration: none;
				color: black;
				img {
					height: 80%;
					max-width: 100%;
					@media screen and (max-width: 768px) {
						height: 60%;
					}
					@media screen and (max-width: 425px) {
						height: 50%;
					}
				}
			}
		}
	}
}
