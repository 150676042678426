.userPage {
	align-items: center !important;
	h1 {
		margin-bottom: 0;
		margin-top: 40px;
		font-size: 18px;
		@media screen and (min-width: 769px) {
			font-size: 26px;
		}
	}
	.orders-link {
		background-color: #f850b3;
		color: white;
		height: 40px;
		width: 80%;
		font-size: 16px;
		border-radius: 10px;
		cursor: pointer;
		margin-bottom: 20px;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: center;
		@media screen and (min-width: 426px) {
			font-size: 20px;
		}
	}

	.user-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 80%;
		@media screen and (min-width: 769px) {
			width: 50%;
			max-width: 450px;
		}
		.user-info {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			row-gap: 10px;
			margin-bottom: 45px;
			.edit-button,
			.save-button,
			.orders-link {
				background-color: red;
				color: white;
				height: 40px;
				width: 130px;
				font-size: 20px;
				border-radius: 10px;
				cursor: pointer;
				margin-bottom: 20px;
			}
			.save-button {
				background-color: green;
			}
			.user-info-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 50px;
				p,
				input {
					font-size: 12px;
					@media screen and (min-width: 426px) {
						font-size: 16px;
					}
					@media screen and (min-width: 769px) {
						font-size: 18px;
					}
					@media screen and (min-width: 1025px) {
						font-size: 20px;
					}
				}
				p {
					font-weight: bold;
				}
				input {
					height: 100%;
				}
				input:read-only {
					cursor: not-allowed;
					border: none;
					background-color: transparent;
				}
			}
			.date {
				gap: 10px;
			}
		}
	}
	.logout-button {
		background-color: red;
		color: white;
		height: 60px;
		width: 200px;
		font-size: 24px;
		font-weight: bold;
		border-radius: 20px;
		cursor: pointer;
		margin-bottom: 20px;
	}
}
.user-wrapper-admin {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	.createProduct {
		display: flex;
		flex-direction: column;
		width: 40%;
		gap: 20px;
		box-sizing: border-box;
		.createProduct-items {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 5px;
			label {
				width: 100%;
				text-align: center;
			}
			input[type='text'], input[type='number'] {
				font-size: 20px;
				box-sizing: border-box;
				width: 100%;
				height: 30px;
				text-align: center;
			}
			textarea {
				font-size: 18px;
				width: 100%;
				height: 100px;
				text-align: center;
			}
		}
		.create{
			height: 35px;
			background-color: green;
			color: white;
			font-size: 20px;
			border-color: #fff;
		}
	}
	.admin-button {
		display: flex;
		flex-direction: column;
		gap: 50px;
		margin-top: 50px;
		width: 30%;
		.orders-link-admin, .logout-button{
			width: 100%;
		}
	}
}
