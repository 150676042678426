.conditions-link{
  position: absolute;
  top: 55px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  color: black;
  font-weight: bold;
  z-index: 1;
  font-size: 12px;
  @media screen and (min-width: 769px) {
    font-size: 16px;
  }
}