.galleriePhotos {
  .homePage-link-back-gallerie{
    top: 25px;
  }
	.gallerie-wrapper {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		margin-bottom: 10px;
		margin-top: 0px;
		padding-bottom: 60px;
		height: 100%;
		column-gap: 2vw;
		row-gap: 7vh;
		overflow-y: scroll;
		scrollbar-width: none;
		width: 100%;
		@media screen and (min-width: 769px) {
			width: 85%;
		}
		.scrollTop {
			position: fixed;
			bottom: 10vh;
			right: 15.3%;
			width: 50px;
			height: 50px;
			background-color: #f850b3;
			border-radius: 50%;
			border: none;
			color: white;
			font-size: 20px;
			cursor: pointer;
			z-index: 10;
		}
		div {
			width: 48%;
			aspect-ratio: 3/2;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			color: black;
			position: relative;
			@media screen and (min-width: 769px) {
				width: 30%;
			}
			img{
				height: 100%;
				max-width: 100%;
			}
			h3 {
				margin: 0;
				padding: 0;
				width: 70%;
				font-size: 12px;
				@media screen and (min-width: 1024px) {
					font-size: 14px;
				}
			}
		}
	}
}
