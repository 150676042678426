.orderRecap {
	h1 {
		font-size: 24px;
		margin-top: 50px;
	}
	.orderRecap-container {
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;
		width: 100%;
		@media screen and (min-width: 769px) {
			flex-direction: row;
		}
		strong {
			margin-right: 2%;
		}
		.orderRecap-items,
		.orderRecap-infos {
			width: 100%;
			@media screen and (min-width: 769px) {
				width: 50%;
			}
			ul {
				list-style: none;
				height: 50%;
				width: 100%;
				box-sizing: border-box;
				padding-left: 10px;
				@media screen and (min-width: 769px) {
					height: 60vh;
					overflow-y: scroll;
				}
				li {
					display: flex;
					width: 100%;
					justify-content: center;
					gap: 2%;
					height: 30vh;
					@media screen and (min-width: 769px) {
						height: 35%;
					}
					img {
						height: 70%;
						max-width: 145px;
						max-height: 230px;
						object-fit: contain;
						@media screen and (min-width: 769px) {
							max-height: none;
							max-width: none;
						}
					}
					.item-infos {
						width: 50%;
					}
				}
			}
		}
	}
}
