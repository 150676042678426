.cart {
	position: absolute;
	top: 0;
	right: 0;
	width: 15%;
	height: 100%;
	background-color: #fff;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 2vh;
	box-sizing: border-box;
	padding: 0 10px;
	@media screen and (max-width: 768px) {
		width: 60%;
		max-width: 300px;
	}
	.closeCart{
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
		padding: 0;
		cursor: pointer;
		z-index: 1000;
		@media screen and (min-width: 769px) {
			display: none;
		}

	}
	h2 {
		width: 100%;
		text-align: center;
		margin: 10px 0;
		@media screen and (min-width: 769px) {
			font-size: 16px;
		}
		@media screen and (min-width: 1024px) {
			font-size: 18px;
		}

	}
	h3 {
		margin: 0;
		@media screen and (min-width: 769px) {
			font-size: 14px;
		}
		@media screen and (min-width: 1024px) {
			font-size: 16px;
		}
	}
	.validerPanier{
		width: 60%;
		aspect-ratio: 16 / 3;
		text-decoration: none;
		color: black;
		background: #f850b3;
		border-radius: 5px;
		font-size: 100%;
		font-weight: bold;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		@media screen and (min-width: 769px) {
			width: 90%;
		}
	}

	ul {
		overflow-y: scroll;
		scrollbar-width: none;	
		width: 100%;
		list-style-type: none;
		padding: 0;
		li {
			width: 100%;
			box-sizing: border-box;
			border-bottom: black	1px dashed;
			padding: 10px 0;
			img {
				height: 10vh;
			}
			input {
				width: 20%;
				height: 100%;
			}
			input[type="number"]::-webkit-inner-spin-button,
			input[type="number"]::-webkit-outer-spin-button {
				margin: 0; /* Supprime les marges internes */
				width: 40%; /* Ajuste la largeur */
				height: 24px; /* Ajuste la hauteur */
        color : black;
			}

			.quantity {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 3vh;
				gap: 5%;
			}
		}
	}
}
