@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.header {
	background: #f850b3;
	height: 5vh;
	width: 100%;
	position: relative;
	display: flex;
	.visually-hidden {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		border: 0;
		clip: rect(0, 0, 0, 0);
		overflow: hidden;
	}
	.main-logo {
		height: 100%;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		@media screen and (min-width: 426px) {
			margin-left: 5px;
			aspect-ratio: 1/1;
		}
		@media screen and (min-width: 769px) {
			margin-left: 20px;
		}
		&:hover {
			animation: spin 2s linear infinite;
		}
		a {
			height: 100%;
			aspect-ratio: 1/1;
		}
		img {
			height: 100%;
			aspect-ratio: 1/1;
		}
	}
	.navbar {
		height: 100%;
		width: 93%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 5px;
		.events-link{
			height: 100%;
			width: 16%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			background-color: yellow;
			color: #000;
			font-size: 12px;
			font-weight: bold;
			box-sizing: border-box;
			margin-left: 10px;
			padding:  10px;
			@media screen and (min-width: 639px) {
				font-size: 14px;
			}
			@media screen and (min-width: 1025px) {
				font-size: 18px;
			}
		}

		.search-form {
			display: flex;
			justify-content: space-between;
			height: 60%;
			max-width: 335px;
			border-radius: 10px;
			box-shadow: none;
			border: none;
			outline: none;
			font-size: 16px;
			color: black;
			padding-left: 10px;
			margin-right: 10px;
			box-sizing: border-box;
			width: 50%;
			@media screen and (min-width: 769px) {
				padding-left: 20px;
				margin-right: 30px;
				
			}
			.search {
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 0 0 10px;
				margin: 0;
				border: none;
				outline: none;
				border-radius: 10px 0 0 10px;
				&::placeholder {
					font-size: 12px;
					@media screen and (min-width: 769px) {
						font-size: 16px;
					}
				}
			}
			.search-button {
				height: 100%;
				width: 20%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0;
				border: none;
				outline: none;
				border-radius: 0 10px 10px 0;
				cursor: pointer;
				background: #fff;
				font-size: 12px;
				@media screen and (min-width: 769px) {
					font-size: 16px;
				}
			}
			.fa-magnifying-glass:hover {
				padding: 0;
				color: #f850b3;
			}
		}
		.connection {
			width: 25%;
			max-width: 100px;
			height: 100%;
			a,
			button {
				display: flex;
				justify-content: center;
				align-items: center;
				text-decoration: none;
				background-color: transparent;
				border: none;
				color: #000;
				font-size: 12px;
				height: 100%;
				width: 100%;
				gap: 0px;
				cursor: pointer;
				@media screen and (min-width: 426px) {
					gap: 10px;
				}
				@media screen and (min-width: 769px) {
					font-size: 16px;
				}
			}
		}
	}
}
