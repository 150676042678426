.productPage {
	gap: 0 !important;
	@media screen and (min-width: 769px) {
		gap: 40px;
	}
	select {
		width: 100%;
		max-width: 100%;
	}

	.productPage-title {
		width: 100%;
		text-align: center;
		height: 5vh;
		margin-top: 7vh;
		margin-bottom: 50px;
		font-size: 20px;
		@media screen and (min-width: 769px) {
			font-size: 30px;
			margin-bottom: 70px;
		}
	}
	.productPage-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 100%;
		gap: 2vw;
		width: 100%;
		@media screen and (min-width: 769px) {
			width: 85%;
			flex-direction: row;
		}
		.productPage-wrapper-img {
			height: 25vh;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-bottom: 20px;
			@media screen and (min-width: 769px) {
				width: 30%;
				height: 35vh;
			}
			@media screen and (min-width: 1025px) {
				height: 45vh;
			}
			img {
				height: 100%;
				max-width: 100%;
				object-fit: contain;
				cursor: zoom-in;
			}
			p{
				margin: 0;
			}
		}
		.productPage-wrapper-descripAndPrice {
			display: flex;
			flex-direction: column;
			height: 75%;
			width: 100%;
			gap: 10px;
			@media screen and (min-width: 769px) {
				width: 60%;
			}
			h2,
			h3 {
				font-weight: bold;
				margin-top: 0;
				font-size: 15px;
				@media screen and (min-width: 769px) {
					font-size: 25px;
				}
			}
			p {
				font-size: 18px;
				margin: 0;
				padding: 0 10px;
			}
			.error {
				color: red;
				font-size: 22px;
				font-weight: bold;
			}
			.productPage-description {
				display: flex;
				flex-direction: column;
			}
			.productPage-price {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				height: 20vh;
				.productPage-price-infos,
				.productPage-price-action,
				.productPage-price-select {
					// width: 50%;
					min-width: 30%;
					padding: 2%;
					box-sizing: border-box;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					// @media screen and (min-width: 769px) {
					// 	align-items: flex-start;
					// }
					button {
						height: 40%;
						background: #f850b3;
						border: none;
						border-radius: 20px;
						color: #fff;
						font-size: 12px;
						font-weight: bold;
						cursor: pointer;
					}
					.productPage-dispo{
						color: green;
						font-weight: bold;
						margin-top: 10px;
						font-size: 15px;
					}
				}
				.productPage-price-select {
					align-items: center;
					justify-content: center;
					gap: 15px;
					label {
						width: 100%;
						text-align: center;
						font-weight: bold;
						font-size: 15px;
						@media screen and (min-width: 769px) {
							font-size: 20px;
						}
					}
				}
			}
		}
	}
}
