.App {
	text-align: center;
}
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}
html {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	background: #f8f4f4;
}
body,
#root {
	margin: 0;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	.homePage-link-back {
		position: absolute;
		top: 55px;
		left: 20px;
		display: flex;
		align-items: center;
		gap: 5px;
		text-decoration: none;
		color: black;
		font-weight: bold;
		z-index: 1;
		font-size: 12px;
		@media screen and (min-width: 769px) {
			font-size: 16px;
		}
		i {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 28px;
			@media screen and (max-width: 425px) {
				font-size: 16px;
			}
		}
	}

	.page {
		position: relative;
		min-height: 90vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 40px;
		box-sizing: border-box;
		@media screen and (max-width: 768px) {
			min-height: 85vh;
			align-items: normal;
		}
		.openCart {
			position: absolute;
			top: 5px;
			right: 20px;
			width: 50px;
			height: 30px;
			box-sizing: border-box;
			cursor: pointer;
			@media screen and (min-width: 769px) {
				display: none;
			}
		}
	
	}
}
