.ordersPage {
  h1{
    margin-top: 50px;
    margin-bottom: 0;
  }
	.ordersTable {
		border-collapse: collapse;
		width: 100%;
    font-size: 8px;
    @media screen and (min-width: 426px) {
      font-size: 10px;
    }
    @media screen and (min-width: 769px) {
      font-size: 15px;
    }
    @media screen and (min-width: 1025px) {
      font-size: 16px;
    }
		thead {
			tr {
				border: 2px solid black;
				th {
					padding: 10px 0;
				}
			}
		}
		tbody {
			tr {
				border-bottom: 1px solid black;
				td {
					padding: 10px 0;
				}
			}
			tr:hover {
				background-color: #f850b3;
			}
		}
	}
}
