.select {
	height: 30%;
	width: 100%;
	max-width: 200px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	z-index: 1;
	border: 1px solid black;
	box-sizing: border-box;
	border-radius: 5px;
	&:hover {
		transition: all 0.5s;
	}
	.select_title {
		width: 100%;
		height: 100%;
		display: flex;
		gap: 10px;
		align-items: center;
		justify-content: center;
		color: #000;
		font-size: 12px;
		font-weight: bold;
		cursor: pointer;
		@media screen and (min-width: 1025px) {
			font-size: 14px;
		}
		i {
			font-size: 15px;
			height: 100%;
		}
	}
	.select_content.hidden {
		transition: all 0.5s 0.2s;
		opacity: 0;
		pointer-events: none;
	}
	.select_content.show {
		transition: all 0.5s 0.2s;
		opacity: 1;
	}
	.fa-chevron-up.rotated {
		transition: all 0.5s;
		transform-origin: center;
		transform: rotate(180deg);
		margin-top: 2px;
		display: flex;
		align-items: center;
	}
	.fa-chevron-up {
		transition: all 0.5s;
		transform-origin: center;
		transform: rotate(0deg);
		margin-top: 7px;
		display: flex;
		align-items: center;
	}
	ul {
		background: #f850b2;
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: start;
		width: 100%;
		height: 380px;
		overflow-y: scroll;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		bottom: 0px;
		left: 0;
		font-size: 18px;
    @media screen and (min-width: 769px) {
      top: 0px;
			height: 340px;
    }
    @media screen and (min-width: 1024px) {
			height: 370px;
    }
    span{
      width: 100%;
      color: #000;
    }
    .title2{
      border-top: #000 1px dashed;
    }
		li {
			list-style: none;
			color: #000;
			width: 100%;
			box-sizing: border-box;
			padding: 5px 0 5px 10%;
			cursor: pointer;
			text-align: left;
			font-size: 12px;
			@media screen and (min-width: 520px) {
				font-size: 12px;
			}
			@media screen and (min-width: 769px) {
				font-size: 16px;
			}
			&:hover {
				transition: all 0.5s;
				box-shadow: inset 0 0 35px 0 rgba(0, 0, 0, 0.2);
			}
			i {
				margin-left: 10px;
			}
			.fa-heart {
				color: #fc0000;
			}
		}
	}
}
