.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: #000;
    position: absolute;
    top: 0;
    z-index: 100000;
    .loading {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        width: auto;
        @media screen and (min-width: 769px) {
            font-size: 30px;
        }
    }
    #spinner {
        position: absolute;
        display: flex;
        width: 200px;
        height: 200px;
        border: 20px solid #fff;
        border-top:20px solid #f850b3;
        border-radius: 100%;
        animation: rotate 2.5s linear infinite;
        @keyframes rotate {
            0% {transform: rotate(0deg);}
            100% {transform: rotate(360deg);}
        }
    }
    
}