.loginPage {
	align-items: center !important;
	gap: 0 !important;
	h1{
		font-size: 20px;
		margin-top: 60px;
		@media screen and (min-width: 426px)  and (max-width: 768px) {
			font-size: 24px;
		}
		@media screen and (min-width: 769px) and (max-width: 1024px) {
			font-size: 28px;
			margin-top: 20px;
		}
		@media screen and (min-width: 1025px) {
			font-size: 32px;
			margin-top: 20px;
		}
	}
	.login-wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 90%;
		height: 85%;
		border-radius: 30px;
		overflow: hidden;
		border: black solid 1px;
		margin-bottom: 20px;
		@media screen and (min-width: 769px) {
			height: 70vh;
			width: 70%;
			top: 50%;
			flex-direction: row;
		}
		h2 {
			font-size: 20px;
			@media screen and (min-width: 426px) {
				font-size: 24px;
			}
			@media screen and (min-width: 769px) {
				font-size: 28px;
			}
			@media screen and (min-width: 1025px) {
				font-size: 32px;
			}
		}
		.login-form {
			width: 100%;
			height: 50%;
			display: flex;
			flex-direction: column;
			align-items: center;
			@media screen and (min-width: 769px) {
				width: 50%;
				height: 100%;
				position: absolute;
				left: 0%;
				gap: 30px;
			}
			form {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 15px;
				@media screen and (min-width: 769px) {
					gap: 35px;
				}
				button[type="submit"] {
					width: 70%;
					max-width: 240px;
					height: 40px;
					font-size: 18px;
					margin-top: 20px;
					cursor: pointer;
					border-radius: 5px;
					border: black 1px solid;
					background-color: #f850b3;
					color: #fff;
					transition: all 0.3s ease-in-out;
					@media screen and (min-width: 769px) {
						width: 40%;
						font-size: 20px;
					}
					&:hover,
					&:focus {
						background-color: #fff;
						color: #f850b3;
					}
				}
				input {
					height: 30px;
					font-size: 12px;
					padding: 0 0 0 5px;
					border-radius: 5px;
					border: black 1px solid;
					@media screen and (min-width: 426px) {
						font-size: 16px;
					}
					@media screen and (min-width: 1025px) {
						font-size: 18px;
					}
				}
				input::placeholder{
					font-size: 12px;
					@media screen and (min-width: 426px) {
						font-size: 16px;
					}
					@media screen and (min-width: 1025px) {
						font-size: 20px;
					}
				}
				.email,
				.password {
					display: flex;
					flex-direction: column;
					width: 70%;
					max-width: 290px;
					gap: 5px;
					font-size: 16px;
					text-align: start;
					@media screen and (min-width: 426px) {
						font-size: 20px;
					}
				}
				.password-input {
					width: 100%;
					height: 30px;
					margin: 0;
					padding: 0;
					box-sizing: border-box;
					display: flex;
					input {
						width: 80%;
						height: 100%;
						border-right: none;
						box-sizing: border-box;
						margin: 0;
						border-radius: 5px 0 0 5px;
					}
					button {
						width: 20%;
						height: 100%;
						border: black 1px solid;
						border-left: none;
						box-sizing: border-box;
						margin: 0;
						padding: 0;
						border-radius: 0 5px 5px 0;
					}
				}
			}
		}
		.signup-form {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 50%;
			overflow-y: scroll;
			@media screen and (min-width: 769px) {
				position: absolute;
				width: 50%;
				height: 100%;
				left: 50%;
			}
			form {
				width: 100%;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				div{
					width: 48%;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					row-gap: 5px;
					@media screen and (min-width: 769px) {
						gap: 0;
					}
					.adresse, .telephone{
						width: 100%;
					}
					.code-postal{
						font-size: 0.9vw;
						width: 35%;
					}
					.ville{
						font-size: 0.9vw;
						width: 62%;
					}
				}
				.newPassword{
					width: 100%;
					max-width: 350px;
					margin: 10px 50px;
					@media screen and (min-width: 769px) {
						margin: 25px 50px;
					}
					&.confirmed{
						font-size: 15px;
						margin-top: 0;
					}
				}
				.signup-btn{
					width: 100%;
					display: flex;
					justify-content: center;
					margin-bottom: 10px;
					button[type="submit"] {
						width: 40%;
						max-width: 240px;
						height: 40px;
						font-size: 22px;
						cursor: pointer;
						border-radius: 5px;
						border: black 1px solid;
						background-color: #f850b3;
						color: #fff;
						transition: all 0.3s ease-in-out;
						@media screen and (min-width: 769px) {
							font-size: 21px;
						}
						&:hover,
						&:focus {
							background-color: #fff;
							color: #f850b3;
						}
					}
				}
				label{
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: flex-start;
					font-size: 22px;
					width: 90%;
					height: 30px;
					gap: 5px;
					@media screen and (min-width: 769px) {
						height: 70px;
					}
					input{
						height: 35px;
						width: 100%;
						font-size: 16px;
						padding: 0 0 0 5px;
						border-radius: 5px;
						border: black 1px solid;
						box-sizing: border-box;
					}
				}
			}
		}
		.slider {
			position: absolute;
			width: 100%;
			height: 50%;
			z-index: 1;
			transition: all 1s;
			background-color: #f850b3;
			@media screen and (min-width: 769px) {
				width: 50%;
				height: 100%;
			}
			button {
				width: 100%;
				height: 100%;
				background-color: transparent;
				border: none;
				cursor: pointer;
				font-size: 24px;
				font-weight: bold;
				@media screen and (min-width: 769px) {
				font-size: 30px;
				}
			}
		}
		.slider-right {
			left: 50%;
		}
		.slider-left {
			left: 0%;
		}
		.slider-bottom {
			bottom: 0%;
		}
		.slider-top {
			bottom: 50%;
		}
	}
}
