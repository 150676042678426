.footer {
	background: #000;
	color: #f850b3;
	height: 5vh;
	display: flex;
	align-items: center;
	justify-content: center;
	@media screen and (max-width: 768px) {
		height: 10vh;
	}

	nav {
		width: 80%;
		@media screen and (max-width: 768px) {
			width: 100%;
		}
		ul {
			margin: 0;
			padding: 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			list-style: none;
			width: 98%;
			@media screen and (max-width: 768px) {
				flex-wrap: wrap;
				row-gap: 5px;
			}

			li {
				cursor: pointer;
				@media screen and (max-width: 649px) {
					width: 45%;
				}
				@media screen and (min-width: 650px) and (max-width: 768px) {
					width: 33%;
				}
				@media screen and (min-width: 769px) {
					width: auto;
				}
				p {
					margin: 0;
					padding: 0;
					font-weight: normal;
					cursor: default;
					@media screen and (max-width: 768px) {
						font-size: 12px;
					}
					@media screen and (min-width: 769px) {
						font-size: 1.1vw;
					}
					@media screen and (min-width: 650px) and (max-width: 768px) {
						font-size: 12px;
					}
				}
				a {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 2px;
					text-decoration: none;
					font-size: 12px;
					@media screen and (min-width: 769px) {
						font-size: 1.1vw;
					}
				}
				.footer-link-facebook {
					color: #1878f1;
				}
				.footer-link-mail {
					color: #71afe5;
				}
				.footer-link-phone {
					color: #fff;
				}
				.footer-link-location {
					color: #1ea362;
				}
				.footer-link-insta {
					color: #dd2a7b;
					img {
						height: 16px;
					}
				}
			}
		}
	}
}
